import axios from "axios";
import _ from "lodash";

const axiosInstance = axios.create({
  baseURL: "https://aggregator-api.kyberswap.com",
  timeout: 10000,
});

const chainNames = {
  "1": "ethereum",
  "56": "bsc",
  "42161": "arbitrum",
  "137": "polygon",
  "10": "optimism",
  "43114": "avalanche",
  "8453": "base",
  "324": "zksync",
  "250": "fantom",
  "59144": "linea",
  "1101": "polygon-zkevm",
  "534352": "scroll",
  "5000": "mantle",
  "81457": "blast"
};

interface GetRouteParams {
  tokenIn: string;
  tokenOut: string;
  amountIn: string;
  gasInclude: boolean;
}

interface RouteSummary {
  tokenIn: string;
  amountIn: string;
  amountInUsd: string;
  tokenInMarketPriceAvailable: boolean;
  tokenOut: string;
  amountOut: string;
  amountOutUsd: string;
  tokenOutMarketPriceAvailable: boolean;
  gas: string;
  gasPrice: string;
  gasUsd: string;
}

interface GetRouteResponseData {
  routeSummary: RouteSummary;
  routerAddress: string;
}

interface BuildTransactionRequestBody {
  routeSummary: RouteSummary;
  sender: string;
  recipient: string;
  deadline?: string;
  slippageTolerance?: number;
  referral?: string;
  source?: string;
  enableGasEstimation?: boolean;
  permit?: string;
  ignoreCappedSlippage?: boolean;
}

interface BuildTransactionResponse {
  amountIn: string,
  amountInUsd: string,
  amountOut: string,
  amountOutUsd: string,
  gas: string,
  gasUsd: string,
  additionalCostUsd: string,
  additionalCostMessage: string,
  outputChange: {
    amount: string,
    percent: number,
    level: number
  },
  data: string,
  routerAddress: string,
  transactionValue: string
}


export async function getRoute(chainId: number, params: GetRouteParams): Promise<GetRouteResponseData> {
  return (await axiosInstance.get(`/${chainNames[chainId]}/api/v1/routes`, {
    params,
    headers: {
      "x-client-id": "coinmatic-terminal"
    }
  })).data.data;
}

export async function buildTransaction(chainId: number, body: BuildTransactionRequestBody): Promise<BuildTransactionResponse> {
  body.source = "coinmatic-terminal";
  return (await axiosInstance.post(`/${chainNames[chainId]}/api/v1/route/build`, body, {
    headers: {
      "x-client-id": "coinmatic-terminal"
    }
  })).data.data;
}

