<template>
  <nav class="sticky-top bg-white border-bottom">
    <b-container style="height: 56px;" class="d-flex flex-row align-items-center justify-content-between">
      <div class="flex-shrink-0">
        <b-button variant="link" to="/swap">Swap</b-button>
        <template v-if="walletAddress">
          <b-button v-if="walletAddress" variant="link" to="/cex-wallet">CEX wallet</b-button>
          <b-button v-if="walletAddress" variant="link" to="/arbitrage">Arbitrage</b-button>
          <b-button v-if="walletAddress" variant="link" to="/cex-hedging">CEX Hedging</b-button>
          <b-button id="header-more-btn" variant="link" class="text-decoration-none">
            More<small><b-icon-caret-down/></small>
          </b-button>
          <b-popover target="header-more-btn" triggers="hover" placement="bottom">
            <b-button class="d-block" variant="link" to="/cex-balance-watch-orders">Balance watch orders</b-button>
            <b-button class="d-block" variant="link" to="/delist-trade-orders">Delist trade orders</b-button>
            <b-button class="d-block" variant="link" to="/feed">Feed</b-button>
            <b-button class="d-block" variant="link" to="/cex-accounts">Manage CEX accounts</b-button>
            <b-button class="d-block" variant="link" to="/private-key-wallets">Private key wallets</b-button>
          </b-popover>
        </template>
      </div>
      <div>
        <b-button v-if="!walletAddress" variant="link" @click="onClickConnectWallet">Connect web3 wallet</b-button>
        <div class="text-right" v-else>
          <div>
            <small class="text-monospace">
              <span class="d-inline d-lg-none">{{ walletAddress | shortened }}</span>
              <span class="d-none d-lg-inline">{{ walletAddress }}</span>
            </small>
          </div>
          <div>
            <small>
              <a class="text-secondary" :href="explorerUrl" target="_blank">Explorer</a> ·
              <a class="text-secondary" :href="debankUrl" target="_blank">DeBank</a> ·
              {{ displayNativeBalance }} {{ nativeAssetSymbol }}
            </small>
          </div>
        </div>
      </div>
    </b-container>
  </nav>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BigNumber from "bignumber.js";
  import BaseComponent from "@/components/BaseComponent";
  import {ethers} from "ethers";
  import * as web3Service from "@/services/web3Service";
  import * as utils from "@/utils";
  import * as constants from "@/constants";

  @Component
  export default class Header extends BaseComponent {

    walletAddress: string = null;
    chainId: number = null;
    displayNativeBalance = "--";
    get nativeAssetSymbol() {
      return constants.nativeAssetTicker[this.chainId] || "";
    }

    refreshAccountTimeoutHandler = null;

    isDestroyed = false;

    get explorerUrl() {
      return utils.getExplorerUrl(this.chainId, "address", this.walletAddress);
    }

    get debankUrl() {
      return `https://debank.com/profile/${this.walletAddress}`;
    }

    mounted() {
      this.refreshAccountState();
    }

    async refreshAccountState() {
      clearTimeout(this.refreshAccountTimeoutHandler);
      if (this.isDestroyed) return;

      try {
        this.walletAddress = await web3Service.getConnectedEvmAddress();
        if (this.walletAddress) {
          this.chainId = await web3Service.getConnectedEvmChainId();

          this.displayNativeBalance = BigNumber(
            ethers.formatEther(await web3Service.getBrowserProvider().getBalance(this.walletAddress))
          ).decimalPlaces(8).toFixed();
        }

      } catch (e) {
        console.error(e);
      }

      clearTimeout(this.refreshAccountTimeoutHandler);
      this.refreshAccountTimeoutHandler = setTimeout(() => this.refreshAccountState(), 5000);
    }

    async onClickConnectWallet() {
      await web3Service.getBrowserProvider().send("eth_requestAccounts", []);
      await this.refreshAccountState();
    }

    destroyed() {
      clearTimeout(this.refreshAccountTimeoutHandler);
      this.isDestroyed = true;
    }

  }
</script>
