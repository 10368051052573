export const CHAIN_ID_ETH = 1;
export const CHAIN_ID_BSC = 56;
export const CHAIN_ID_POLYGON = 137;
export const CHAIN_ID_AVAX_C = 43114;
export const CHAIN_ID_ARBITRUM = 42161;
export const CHAIN_ID_OPTIMISM = 10;
export const CHAIN_ID_BASE = 8453;
export const CHAIN_ID_FANTOM = 250;
export const CHAIN_ID_SOLANA = -896789948;
export const CHAIN_ID_TON = -1141272988;
export const CHAIN_ID_TRON = 728126428;

export const NATIVE_ASSET_ADDRESS = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
export const ZERO_ADDRESS_TRON = "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb";
export const SUNSWAP_ROUTER = "TFVisXFaijZfeyeSjCEVkHfex7HGdTxzF9";
export const WSOL = "So11111111111111111111111111111111111111112";
export const NATIVE_ADDRESS_TON = "EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c";
export const REFERRER_ADDRESS = "0xf919bFA27Db54cC1D397b6b9157bdF36e98C340E";

export const chainNames = {
  [CHAIN_ID_ETH]: "Ethereum",
  [CHAIN_ID_BSC]: "BNB Chain",
  [CHAIN_ID_POLYGON]: "Polygon",
  [CHAIN_ID_AVAX_C]: "Avalanche C-Chain",
  [CHAIN_ID_ARBITRUM]: "Arbitrum",
  [CHAIN_ID_OPTIMISM]: "Optimism",
  [CHAIN_ID_BASE]: "Base",
  [CHAIN_ID_FANTOM]: "Fantom",
  [CHAIN_ID_SOLANA]: "Solana",
  [CHAIN_ID_TON]: "Toncoin",
  [CHAIN_ID_TRON]: "Tron",
};

export const chainNamesShort = {
  [CHAIN_ID_ETH]: "ETH",
  [CHAIN_ID_BSC]: "BSC",
  [CHAIN_ID_POLYGON]: "POL",
  [CHAIN_ID_AVAX_C]: "AVAXC",
  [CHAIN_ID_ARBITRUM]: "ARB",
  [CHAIN_ID_OPTIMISM]: "OP",
  [CHAIN_ID_BASE]: "BASE",
  [CHAIN_ID_FANTOM]: "FTM",
  [CHAIN_ID_SOLANA]: "SOL",
  [CHAIN_ID_TON]: "TON",
  [CHAIN_ID_TRON]: "TRX",
};

export const nativeAssetTicker = {
  [CHAIN_ID_ETH]: "ETH",
  [CHAIN_ID_BSC]: "BNB",
  [CHAIN_ID_POLYGON]: "POL",
  [CHAIN_ID_AVAX_C]: "AVAX",
  [CHAIN_ID_ARBITRUM]: "ETH",
  [CHAIN_ID_OPTIMISM]: "ETH",
  [CHAIN_ID_BASE]: "ETH",
  [CHAIN_ID_FANTOM]: "FTM",
  [CHAIN_ID_SOLANA]: "SOL",
  [CHAIN_ID_TON]: "TON",
  [CHAIN_ID_TRON]: "TRX",
};

export const gasStep = {
  [CHAIN_ID_ETH]: 0.01,
  [CHAIN_ID_BSC]: 0.1,
  [CHAIN_ID_POLYGON]: 1,
  [CHAIN_ID_AVAX_C]: 1,
  [CHAIN_ID_ARBITRUM]: 0.001,
  [CHAIN_ID_OPTIMISM]: 0.0001,
  [CHAIN_ID_BASE]: 0.0001,
  [CHAIN_ID_FANTOM]: 1,
};

export const explorerUrl = {
  "all": "https://blockscan.com",
  [CHAIN_ID_ETH]: "https://etherscan.io",
  [CHAIN_ID_BSC]: "https://bscscan.com",
  [CHAIN_ID_POLYGON]: "https://polygonscan.com",
  [CHAIN_ID_AVAX_C]: "https://snowtrace.io",
  [CHAIN_ID_ARBITRUM]: "https://arbiscan.io",
  [CHAIN_ID_OPTIMISM]: "https://optimistic.etherscan.io",
  [CHAIN_ID_BASE]: "https://basescan.org",
  [CHAIN_ID_FANTOM]: "https://ftmscan.com",
};

export const exchangeNames = {
  binance: "Binance",
  "binance-futures": "Binance Futures",
  huobi: "HTX",
  "huobi-futures": "HTX Futures",
  okx: "OKX",
  "okx-futures": "OKX Futures",
  kucoin: "Kucoin",
  "kucoin-futures": "Kucoin Futures",
  mexc: "MEXC",
  "mexc-futures": "MEXC Futures",
  gate: "Gate",
  "gate-futures": "Gate Futures",
  bybit: "Bybit",
  "bybit-futures": "Bybit Futures",
  bitget: "Bitget",
  "bitget-futures": "Bitget Futures",
  upbit: "Upbit",
};

export const AUTH_HEADER_PREFIX = "arb-trader-coinmatic";

export const NETWORK_LOGO = {
  [CHAIN_ID_ETH]: "https://app.1inch.io/assets/images/network-logos/ethereum.svg",
  [CHAIN_ID_BSC]: "https://app.1inch.io/assets/images/network-logos/bsc_2.svg",
  [CHAIN_ID_POLYGON]: "https://app.1inch.io/assets/images/network-logos/polygon_1.svg",
  [CHAIN_ID_ARBITRUM]: "https://app.1inch.io/assets/images/network-logos/arbitrum_2.svg",
  [CHAIN_ID_OPTIMISM]: "https://app.1inch.io/assets/images/network-logos/optimism.svg",
  [CHAIN_ID_AVAX_C]: "https://app.1inch.io/assets/images/network-logos/avalanche.svg",
  [CHAIN_ID_BASE]: "https://app.1inch.io/assets/images/network-logos/base.svg",
  [CHAIN_ID_FANTOM]: "https://app.1inch.io/assets/images/network-logos/fantom.svg",
  [CHAIN_ID_SOLANA]: "https://coin-images.coingecko.com/coins/images/4128/large/solana.png",
  [CHAIN_ID_TON]: "https://coin-images.coingecko.com/coins/images/17980/large/ton_symbol.png",
  [CHAIN_ID_TRON]: "https://coin-images.coingecko.com/coins/images/1094/large/tron-logo.png"
};

export const EXCHANGE_LOGO = {
  binance: "/img/exchanges/binance.svg",
  "binance-futures": "/img/exchanges/binance.svg",
  huobi: "/img/exchanges/huobi.svg",
  "huobi-futures": "/img/exchanges/huobi.svg",
  kucoin: "/img/exchanges/kucoin.svg",
  "kucoin-futures": "/img/exchanges/kucoin.svg",
  okx: "/img/exchanges/okx.svg",
  "okx-futures": "/img/exchanges/okx.svg",
  mexc: "/img/exchanges/mexc.svg",
  "mexc-futures": "/img/exchanges/mexc.svg",
  gate: "/img/exchanges/gate.svg",
  "gate-futures": "/img/exchanges/gate.svg",
  bybit: "/img/exchanges/bybit.svg",
  "bybit-futures": "/img/exchanges/bybit.svg",
  upbit: "/img/exchanges/upbit.svg",
  bitget: "/img/exchanges/bitget.svg",
  "bitget-futures": "/img/exchanges/bitget.svg",
};


export const dexScreenerChainIds = {
  [CHAIN_ID_ETH]: "ethereum",
  [CHAIN_ID_BSC]: "bsc",
  [CHAIN_ID_POLYGON]: "polygon",
  [CHAIN_ID_AVAX_C]: "avalanche",
  [CHAIN_ID_ARBITRUM]: "arbitrum",
  [CHAIN_ID_OPTIMISM]: "optimism",
  [CHAIN_ID_BASE]: "base",
  [CHAIN_ID_FANTOM]: "fantom",
  [CHAIN_ID_SOLANA]: "solana",
  [CHAIN_ID_TON]: "ton",
  [CHAIN_ID_TRON]: "tron",
};

export const dexToolsChainIds = {
  [CHAIN_ID_ETH]: "ether",
  [CHAIN_ID_BSC]: "bnb",
  [CHAIN_ID_POLYGON]: "polygon",
  [CHAIN_ID_AVAX_C]: "avalanche",
  [CHAIN_ID_ARBITRUM]: "arbitrum",
  [CHAIN_ID_OPTIMISM]: "optimism",
  [CHAIN_ID_BASE]: "base",
  [CHAIN_ID_FANTOM]: "fantom",
  [CHAIN_ID_SOLANA]: "solana",
  [CHAIN_ID_TON]: "ton",
  [CHAIN_ID_TRON]: "tron",
};


export const USDT_TOKEN: Record<string, { address: string, symbol: string, decimals: number }> = {
  [CHAIN_ID_ETH]: { address: "0xdAC17F958D2ee523a2206206994597C13D831ec7", symbol: "USDT", decimals: 6 },
  [CHAIN_ID_BSC]: { address: "0x55d398326f99059fF775485246999027B3197955", symbol: "USDT", decimals: 18 },
  [CHAIN_ID_POLYGON]: { address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F", symbol: "USDT", decimals: 6 },
  [CHAIN_ID_AVAX_C]: { address: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7", symbol: "USDT", decimals: 6 },
  [CHAIN_ID_ARBITRUM]: { address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9", symbol: "USDT", decimals: 6 },
  [CHAIN_ID_OPTIMISM]: { address: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58", symbol: "USDT", decimals: 6 },
  [CHAIN_ID_BASE]: { address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913", symbol: "USDC", decimals: 6 },
  [CHAIN_ID_FANTOM]: { address: "0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf", symbol: "USDC", decimals: 6 },
  [CHAIN_ID_SOLANA]: { address: "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB", symbol: "USDT", decimals: 6 },
  [CHAIN_ID_TON]: { address: "EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs", symbol: "USDT", decimals: 6 },
  [CHAIN_ID_TRON]: { address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t", symbol: "USDT", decimals: 6 },
};

export const MULTICALL3_CONTRACT = {
  [CHAIN_ID_ETH]: "0xcA11bde05977b3631167028862bE2a173976CA11",
  [CHAIN_ID_BSC]: "0xcA11bde05977b3631167028862bE2a173976CA11",
  [CHAIN_ID_POLYGON]: "0xcA11bde05977b3631167028862bE2a173976CA11",
  [CHAIN_ID_AVAX_C]: "0xcA11bde05977b3631167028862bE2a173976CA11",
  [CHAIN_ID_ARBITRUM]: "0xcA11bde05977b3631167028862bE2a173976CA11",
  [CHAIN_ID_OPTIMISM]: "0xcA11bde05977b3631167028862bE2a173976CA11",
  [CHAIN_ID_BASE]: "0xcA11bde05977b3631167028862bE2a173976CA11",
  [CHAIN_ID_FANTOM]: "0xcA11bde05977b3631167028862bE2a173976CA11",
  [CHAIN_ID_TRON]: "TEazPvZwDjDtFeJupyo7QunvnrnUjPH8ED"
};


export const leveragedTokenSuffixes = ["2L", "2S", "3L", "3S", "5L", "5S", "UP", "DOWN"];
export const stableCoinSymbols = ["USDT", "USDC", "DAI", "FDUSD"];

export const HEARTBEAT_INTERVAL = 5000;
