import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'vue-class-component/hooks';
import _ from "lodash";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import "@/app.scss";

import { Buffer } from "buffer";
(globalThis as any).Buffer = Buffer;

import * as web3Service from "@/services/web3Service";

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

const checkInjectedInterval = setInterval(() => {
  if (window.ethereum) {
    clearInterval(checkInjectedInterval);
    const foundTs = Date.now();
    if (window.ethereum.isOneInchIOSWallet || window.ethereum.isOneInchAndroidWallet) {
      (async () => {
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        window.ethereum.on('chainChanged', (newChainId: string) => {
          if (newChainId === chainId) return;
          console.log(`window.ethereum.on('chainChanged')`, { newChainId });
          location.reload();
        });

        window.ethereum.on('accountsChanged', (newAccounts: string[]) => {
          if (_.isEqual(accounts, newAccounts)) return;
          console.log(`window.ethereum.on('accountsChanged')`, newAccounts);
          location.reload();
        });
      })();

    } else {
      window.ethereum.on('chainChanged', (chainId: string) => {
        const timeSinceFound = Date.now() - foundTs;
        console.log(`window.ethereum.on('chainChanged')`, { chainId }, "timeSinceFound", timeSinceFound);
        if (timeSinceFound > 1000) {
          location.reload();
        }
      });

      window.ethereum.on('accountsChanged', (accounts: string[]) => {
        const timeSinceFound = Date.now() - foundTs;
        console.log(`window.ethereum.on('accountsChanged')`, accounts, "timeSinceFound", timeSinceFound);
        if (timeSinceFound > 1000) {
          location.reload();
        }
      });
    }

    new Vue({
      router,
      render: h => h(App)
    }).$mount('#app');
  }
}, 50);

/*const checkSolanaInjectedInterval = setInterval(() => {
  const provider = web3Service.getSolanaProvider();
  if (provider) {
    clearInterval(checkSolanaInjectedInterval);
    const handler = () => {
      if (["/arbitrage-focus-solana", "/swap-solana"].includes(location.pathname)) {
        location.reload();
      }
    };
    provider.on("disconnect", handler);
    provider.on("accountChanged", handler);
  }
}, 250);*/

addEventListener("click", event => {
  console.log("event listener once click");
  window["canPlayAudio"] = true;
}, { once: true });

Vue.config.productionTip = false;
