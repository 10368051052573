<template>
  <div>
    <div v-for="wallet of walletOptions" :key="wallet.address"
         class="py-2 border-bottom bg-hover cursor-pointer d-flex flex-row align-items-center justify-content-between"
         @click="pickWallet(wallet)">
      <div class="flex-grow-1 min-width-0">
        <div class="text-secondary overflow-hidden text-overflow-ellipsis">
          <template v-if="wallet.isInjected">Browser wallet</template>
          <template>{{ wallet.label }}</template>
        </div>
        <div class="text-monospace text-break">{{ wallet.address }}</div>
      </div>
      <div class="flex-shrink-0">

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import * as privateKeyWalletService from "@/services/privateKeyWalletService";
  import * as web3Service from "@/services/web3Service";

  @Component
  export default class ChangeWalletModal extends BaseComponent {

    @Prop() modalId!: string;
    @Prop() type?: string;

    walletOptions = [];

    mounted() {
      const privateKeyWallets = privateKeyWalletService.getWallets().filter(wallet => wallet.type === "solana");
      this.walletOptions = [
        { isInjected: true },
        ...privateKeyWallets
      ];
      this.fillInInjectedWalletAddress();
    }

    async fillInInjectedWalletAddress() {
      try {
        const provider = await web3Service.waitForSolanaBrowserProvider(AbortSignal.timeout(3000));
        if (!provider.isConnected) {
          await provider.connect({ onlyIfTrusted: true });
        }
        this.$set(this.walletOptions[0], "address", provider.publicKey.toString());
      } catch (e) {
        console.error(e);
      }
    }

    @Emit()
    pickWallet(savedWallet) {
      return savedWallet;
    }

  }
</script>
