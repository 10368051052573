<template>
  <div>
    <div>
      <b-spinner v-if="currentStepIndex === 0" small />
      <b-icon-check v-if="currentStepIndex > 0"></b-icon-check>
      <span>
        Waiting for swap tx confirmation.
        <a :href="confirmationStrategy.signature | explorerUrl(chainId, 'tx')" target="_blank">View in explorer</a>
      </span>
    </div>
    <div>
      <b-spinner v-if="currentStepIndex === 1" small />
      <b-icon-check v-if="currentStepIndex > 1"></b-icon-check>
      <span>Checking account balances</span>
    </div>
    <div>
      <b-spinner v-if="currentStepIndex === 2" small />
      <b-icon-check v-if="currentStepIndex > 2"></b-icon-check>
      <span>Composing transfer tx </span>
      <a
        v-if="sendToDestReceiverTxConfirmationStrategy"
        :href="sendToDestReceiverTxConfirmationStrategy.signature | explorerUrl(chainId, 'tx')" target="_blank">
        View in explorer
      </a>
    </div>
    <div v-if="errorMsg" class="text-danger text-break">{{ errorMsg }}</div>
    <div class="text-right">
      <b-button v-if="currentStepIndex >= 3" @click="hideModal" variant="outline-primary">OK</b-button>
      <b-button v-else @click="hideModal" variant="outline-secondary">Cancel</b-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script lang="ts">
  import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
  import _ from "lodash";
  import BaseComponent from "@/components/BaseComponent";
  import BigNumber from "bignumber.js";
  import {
    PublicKey, SystemProgram,
    Transaction, type TransactionConfirmationStrategy,
    TransactionInstruction,
    TransactionMessage,
    VersionedTransaction
  } from "@solana/web3.js";
  import * as web3Service from "@/services/web3Service";
  import * as utils from "@/utils";
  import * as constants from "@/constants";
  import {
    createAssociatedTokenAccountIdempotentInstruction, createTransferCheckedInstruction,
    getAssociatedTokenAddressSync,
    unpackAccount
  } from "@solana/spl-token";

  @Component
  export default class WaitThenSendOutputToDestReceiverModal extends BaseComponent {

    readonly chainId = constants.CHAIN_ID_SOLANA;

    @Prop({ required: true }) modalId: string;
    @Prop({ required: true }) txConfirmationStrategy: TransactionConfirmationStrategy;
    @Prop({ required: true }) mint: string;
    @Prop({ required: true }) decimals: number;
    @Prop({ required: true }) balanceBeforeSwap: BigNumber.Value;
    @Prop({ required: true }) minimumTransferAmount: BigNumber.Value;
    @Prop({ required: true }) destReceiver: string;

    currentStepIndex = 0;
    sendToDestReceiverTxConfirmationStrategy: TransactionConfirmationStrategy = null;
    errorMsg = "";
    isDestroyed = false;

    mounted() {
      this.process();
    }

    async process() {
      console.log("WaitThenSendOutputToDestReceiverModal process");
      try {
        this.currentStepIndex = 0;
        const txResponse = await web3Service.solanaWeb3.confirmTransaction(this.txConfirmationStrategy);
        if (txResponse.value.err) {
          throw txResponse.value.err;
        }

        this.currentStepIndex = 1;
        const provider = await web3Service.getSolanaProvider();
        const walletPk = provider.publicKey;
        const destReceiverPk = new PublicKey(this.destReceiver);

        if (this.mint === constants.WSOL) {
          while (!this.isDestroyed) {
            await utils.delay(500);
            const walletAccount = await web3Service.solanaWeb3.getAccountInfo(walletPk, "processed");
            const walletBalance = walletAccount?.lamports || 0;
            const transferAmountBN = BigNumber(walletBalance)
              .minus(this.balanceBeforeSwap)
              .decimalPlaces(0);

            if (transferAmountBN.gte(0.01e9)) {
              this.currentStepIndex = 2;
              const blockhashWithExpiry = await web3Service.solanaWeb3.getLatestBlockhash();
              const transaction = new VersionedTransaction(
                new TransactionMessage({
                  recentBlockhash: blockhashWithExpiry.blockhash,
                  payerKey: walletPk,
                  instructions: [
                    SystemProgram.transfer({
                      fromPubkey: walletPk,
                      toPubkey: destReceiverPk,
                      lamports: BigInt(transferAmountBN.toFixed())
                    })
                  ]
                }).compileToV0Message()
              );
              if (!this.isDestroyed) {
                const signedTransaction = await provider.signTransaction(transaction);
                const sendToDestReceiverTxSignature = await web3Service.solanaSpamSendTx(signedTransaction);
                this.sendToDestReceiverTxConfirmationStrategy = {
                  signature: sendToDestReceiverTxSignature,
                  blockhash: blockhashWithExpiry.blockhash,
                  lastValidBlockHeight: blockhashWithExpiry.lastValidBlockHeight
                };
                this.currentStepIndex = 3;
                this.done(this.sendToDestReceiverTxConfirmationStrategy);
              }
              break;
            }
          }

        } else {
          const mintPk = new PublicKey(this.mint);
          const walletTokenAccountPk = getAssociatedTokenAddressSync(mintPk, walletPk);
          console.log("walletTokenAccountPk", walletTokenAccountPk.toString());
          const destReceiverTokenAccountPk = getAssociatedTokenAddressSync(mintPk, destReceiverPk);
          console.log("destReceiverTokenAccountPk", destReceiverTokenAccountPk);

          const fetchAccountPks = [walletTokenAccountPk, destReceiverTokenAccountPk];

          while (!this.isDestroyed) {
            await utils.delay(500);
            const [walletTokenAccount, destReceiverTokenAccount] = (await web3Service.solanaWeb3.getMultipleAccountsInfo(fetchAccountPks, "processed"))
              .map((accountInfo, i) => accountInfo && unpackAccount(fetchAccountPks[i], accountInfo));

            const walletTokenAccountBalance = walletTokenAccount?.amount || 0n;
            const transferAmountBN = BigNumber(walletTokenAccountBalance.toString())
              .minus(this.balanceBeforeSwap)
              .decimalPlaces(0);

            if (transferAmountBN.gte(this.minimumTransferAmount)) {
              this.currentStepIndex = 2;
              const instructions: TransactionInstruction[] = [];
              if (!destReceiverTokenAccount) {
                instructions.push(createAssociatedTokenAccountIdempotentInstruction(
                  walletPk,
                  destReceiverTokenAccountPk,
                  destReceiverPk,
                  mintPk
                ));
              }
              instructions.push(createTransferCheckedInstruction(
                walletTokenAccountPk,
                mintPk,
                destReceiverTokenAccountPk,
                walletPk,
                BigInt(transferAmountBN.toFixed()),
                this.decimals
              ));
              const blockhashWithExpiry = await web3Service.solanaWeb3.getLatestBlockhash();
              const messageV0 = new TransactionMessage({
                payerKey: walletPk,
                recentBlockhash: blockhashWithExpiry.blockhash,
                instructions,
              }).compileToV0Message();
              const transaction = new VersionedTransaction(messageV0);

              if (!this.isDestroyed) {
                const signedTransaction = await provider.signTransaction(transaction);
                const sendToDestReceiverTxSignature = await web3Service.solanaSpamSendTx(signedTransaction);
                this.sendToDestReceiverTxConfirmationStrategy = {
                  signature: sendToDestReceiverTxSignature,
                  blockhash: blockhashWithExpiry.blockhash,
                  lastValidBlockHeight: blockhashWithExpiry.lastValidBlockHeight
                };
                this.currentStepIndex = 3;
                this.done(this.sendToDestReceiverTxConfirmationStrategy);
              }
              break;
            }
          }
        }

      } catch (e) {
        console.error(e);
        this.errorMsg = e.response?.data?.description || e.response?.data?.error || e.message;
      }
    }

    @Emit()
    done(confirmationStrategy: TransactionConfirmationStrategy) {
      return confirmationStrategy;
    }

    hideModal() {
      this.$bvModal.hide(this.modalId);
    }

    destroyed() {
      this.isDestroyed = true;
    }

  }
</script>
