import { render, staticRenderFns } from "./SolanaTxStatusBanner.vue?vue&type=template&id=4e1e2d3c&scoped=true"
import script from "./SolanaTxStatusBanner.vue?vue&type=script&lang=ts"
export * from "./SolanaTxStatusBanner.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e1e2d3c",
  null
  
)

export default component.exports